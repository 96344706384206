import { View, StyleSheet } from 'react-native';
import React from 'react';
import TextCardInfo from '../TextComponents/Home/TextCardInfo';
import TextCardInfoTitle from '../TextComponents/Home/TextCardInfoTitle';
import { InfoActivityProps } from '../../utils/types';
import { useTourInfo } from './utils';
import { ActivityType } from '../../utils/enums';
import MeetingActivityItemInfo from '../MeetingActivityItemInfo';

const TourInfo = ({ activityViewDetail, activityType }: InfoActivityProps) => {
  const { description, itinerary, additionalInfo, weather, location } =
    useTourInfo(activityViewDetail);
  return (
    <View style={styles.container}>
      {description && (
        <>
          <TextCardInfoTitle>Description:</TextCardInfoTitle>
          <TextCardInfo>{description}</TextCardInfo>
        </>
      )}
      {location && activityType === ActivityType.Meeting && (
        <>
          <MeetingActivityItemInfo activityViewDetail={activityViewDetail} />
        </>
      )}
      {location && activityType !== ActivityType.Meeting && (
        <>
          <TextCardInfoTitle>Location:</TextCardInfoTitle>
          <TextCardInfo>{location}</TextCardInfo>
        </>
      )}
      {itinerary && (
        <>
          <TextCardInfoTitle>Itinerary:</TextCardInfoTitle>
          <TextCardInfo>{itinerary}</TextCardInfo>
        </>
      )}
      {(additionalInfo || weather) && (
        <>
          <TextCardInfoTitle>Additional Information:</TextCardInfoTitle>
          {additionalInfo ? (
            <TextCardInfo>{additionalInfo}</TextCardInfo>
          ) : null}
          {weather ? <TextCardInfo>{weather}</TextCardInfo> : null}
        </>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    marginTop: 25,
  },
});

export default TourInfo;
