import React, { cloneElement, useEffect, useRef, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Image,
  Modal,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
} from 'react-native';
import { colourConst, fontFamilyConst } from '../../globalStyles';
import Swiper from 'react-native-swiper/src';
import { PhotosCategory, CustomDigitalAssets } from '../../types';
import { useSecureContext } from '../../context';
import { useInfiniteImagesFetcher } from '../../hooks/useInfiniteImageFetcher';
import { DigitalAssetUseType } from '../../utils/enums';

interface TestGalleryProps {
  mediasBatches: string[];
  medias: PhotosCategory[] | undefined;
}

const TestGallery: React.FC<TestGalleryProps> = ({ medias, mediasBatches }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState<
    (CustomDigitalAssets & { index: number }) | null
  >(null);
  const { token } = useSecureContext();
  const observerRef = useRef(null);

  const { photos, hasNextPage, getMoreImages } = useInfiniteImagesFetcher({
    token,
    QueryKey: 'thumbnailImages',
    UseType: DigitalAssetUseType.Thumb,
    batchesArray: mediasBatches,
    mediaItems: medias,
  });
  const { photos: hdPhotos, getMoreImages: getMoreHdImages } =
    useInfiniteImagesFetcher({
      token,
      QueryKey: 'hdImages',
      UseType: DigitalAssetUseType.Web,
      batchesArray: mediasBatches,
      mediaItems: medias,
    });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          getMoreHdImages();
          getMoreImages();
        }
      },
      { threshold: 0.5 }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => observer.disconnect();
  }, [getMoreHdImages, getMoreImages]);

  const renderItem = ({
    item,
    index,
  }: {
    item: CustomDigitalAssets;
    index: number;
  }) => (
    <TouchableOpacity
      style={[styles.imageContainer]}
      onPress={() => {
        setSelectedImage({ ...item, index });
        setModalVisible(true);
      }}
    >
      <Image
        source={{ uri: item.Url }}
        style={styles.image}
        resizeMode="contain"
      />
    </TouchableOpacity>
  );

  if (photos && Array.isArray(photos)) {
    return (
      <View style={{ flex: 1 }}>
        <FlatList
          data={photos}
          renderItem={renderItem}
          keyExtractor={(item) => item.Id.toString()}
          numColumns={2}
          contentContainerStyle={styles.listContainer}
          ListFooterComponent={
            hasNextPage ? (
              <ActivityIndicator
                ref={observerRef}
                size="large"
                color={colourConst.blue}
              />
            ) : null
          }
        />
        <Modal
          animationType="slide"
          transparent={false}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(false);
          }}
        >
          <SafeAreaView style={styles.modalContainer}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setModalVisible(false)}
            >
              <Text style={styles.closeButtonText}>Close</Text>
            </TouchableOpacity>
            <Swiper
              loop={false}
              index={selectedImage?.index}
              showsPagination={false}
              showsButtons={true}
            >
              {hdPhotos.map((item, index) => (
                <Image
                  key={index}
                  source={{ uri: item.Url }}
                  style={styles.modalImage}
                  resizeMode="contain"
                />
              ))}
            </Swiper>
          </SafeAreaView>
        </Modal>
      </View>
    );
  } else {
    return null;
  }
};
export default TestGallery;
const styles = StyleSheet.create({
  listContainer: {
    paddingHorizontal: 8,
    paddingBottom: 16,
  },
  imageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 8,
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: '#f0f0f0',
    width: '100%',
    aspectRatio: 1,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'black',
  },
  closeButton: {
    position: 'absolute',
    top: 56,
    right: 30,
    zIndex: 1,
  },
  closeButtonText: {
    color: colourConst.inactiveGray,
    fontFamily: fontFamilyConst.regular,
    fontSize: 18,
    textShadowColor: 'black',
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 2,
  },
  modalImage: {
    width: '100%',
    height: '100%',
  },
});
