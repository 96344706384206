import React from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { Image } from 'expo-image';
import { colourConst } from '../../globalStyles';
import RainbowLine from '../RainbowLine';
import { MoreParams } from '../../utils/types';
import { RouteProp, useRoute } from '@react-navigation/native';
import ReactMarkdown from 'react-markdown';
import { fontSize, fontFamilyConst } from '../../globalStyles';
import { useQuery } from 'react-query';
import { fetchExperiences } from '../../helpers/fetchExperiences';
import { useSecureContext } from '../../context';
import { Activities } from '../../helpers/activityInterfaces';
import { DigitalAssetType } from '../../utils/enums';
import fetchDigitalAssets from '../../helpers/fetchDigitalAssets';
import ExperienceCard from '../ExperienceCard/ExperienceCard';

const LocalExperiences = () => {
  const experiencesRoute = useRoute<RouteProp<MoreParams, 'Item'>>();
  const { Information, Image_Url, Title } = experiencesRoute.params.item;
  const { token, locationAndWave } = useSecureContext();

  const { data } = useQuery<Activities>({
    queryKey: ['experiences', locationAndWave?.ProjectId],
    queryFn: async () =>
      fetchExperiences({
        token,
        parentProjectId: locationAndWave?.ProjectId,
      }),
    enabled: Boolean(token && locationAndWave?.ProjectId),
    select: (data) => ({
      ...data,
      Activities: data.Activities.sort((a, b) => a.Name.localeCompare(b.Name)),
    }),
  });
  //   console.log('LOCATION AND WAVE:>> ', locationAndWave);
  // console.log('data useQuery:>> ', data);
  ///Getting assets
  const localExperiences = data?.Activities;

  const digitalAssetIdsToFetch = localExperiences?.map(
    (experience) => experience.ImageUrlMobile
  );
  const digitalAssetssParams = {
    digitalAssetType: DigitalAssetType.MobileGeneral,
    idsGuidsCsv: digitalAssetIdsToFetch?.join(',') || '',
  };

  const { data: imagesData, isSuccess: isImagesSuccess } = useQuery({
    queryKey: ['digitalAssetsExperiences', digitalAssetssParams],
    queryFn: async () => fetchDigitalAssets(token, digitalAssetssParams),
    enabled: Boolean(token && digitalAssetIdsToFetch?.length),
  });
  // console.log('imagesData :>> ', imagesData);
  ///
  return (
    <View style={styles.pageContainer}>
      <RainbowLine>
        <ScrollView style={styles.main}>
          <Image style={styles.img} source={Image_Url} />
          <View style={styles.container}>
            <Text style={styles.title}>{Title}</Text>
            <ReactMarkdown
              components={{
                p: ({ children }) => (
                  <Text style={styles.textIntro}> {children}</Text>
                ),
              }}
            >
              {Information}
            </ReactMarkdown>
            {isImagesSuccess && (
              <View style={styles.cardContainer}>
                {data?.Activities.map((activity, index) => {
                  return (
                    <ExperienceCard
                      imagesData={imagesData?.[index]}
                      activity={activity}
                      key={index}
                    />
                  );
                })}
              </View>
            )}
          </View>
        </ScrollView>
      </RainbowLine>
    </View>
  );
};

export default LocalExperiences;
const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  main: {
    flex: 1,
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },

  img: {
    width: '100%',
    aspectRatio: 1.75,
    marginBottom: 15,
  },
  title: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.medium,
    fontWeight: 'bold',
    color: colourConst.font,
    marginBottom: 5,
  },
  textIntro: {
    fontFamily: fontFamilyConst.medium,
    fontSize: fontSize.medium,
    color: colourConst.font,
    textAlign: 'left',
    lineHeight: 22,
    marginBottom: 20,
    whiteSpace: 'pre-line',
  },
  cardContainer: {
    width: '100%',
  },
});
