import { View, StyleSheet } from 'react-native';
import React from 'react';
import TextCardInfo from '../TextComponents/Home/TextCardInfo';
import { InfoActivityProps } from '../../utils/types';
import TextCardInfoTitle from '../TextComponents/Home/TextCardInfoTitle';
import TextCardInfoMeeting from '../TextComponents/Home/TextCardInfoMeeting';

const MeetingActivityItemInfo = ({ activityViewDetail }: InfoActivityProps) => {
  const processItem = (item: { Name: string; Content: string }) => {
    if (!item.Content) {
      return null;
    }
    const parts = item.Content.split(' - ');
    let location: string | null = null;
    let content = null;

    if (parts.length === 2) {
      location = parts[0];
      const jsonStartIndex = parts[1].indexOf('{');
      if (jsonStartIndex !== -1) {
        location += parts[1].substring(0, jsonStartIndex);
        content = JSON.parse(parts[1].substring(jsonStartIndex));
      } else {
        content = JSON.parse(parts[1]);
      }
    } else {
      const jsonStartIndex = parts[0].indexOf('{');
      if (jsonStartIndex !== -1) {
        location = parts[0].substring(0, jsonStartIndex);
        content = JSON.parse(parts[0].substring(jsonStartIndex));
      } else {
        content = JSON.parse(parts[0]);
      }
    }

    let infoArray: (string | null)[] = [];
    if (content) {
      infoArray = Object.keys(content)
        //could be in capital letters or not
        .map((key) => {
          const lowerKey = key.toLowerCase();
          if (lowerKey.includes('table')) {
            return `Table: ${content ? content[key] : ''}`;
          }
          if (lowerKey.includes('main')) {
            return `Main: ${content ? content[key] : ''}`;
          }
          if (lowerKey.includes('starter')) {
            return `Starter: ${content ? content[key] : ''}`;
          }
          return null;
        })
        .filter(Boolean); // remove null values
    }

    return (
      <View key={item.Name}>
        <TextCardInfoTitle>{item.Name}</TextCardInfoTitle>
        {location && (
          <TextCardInfoMeeting>{`Location: ${location}`}</TextCardInfoMeeting>
        )}
        {infoArray.map(
          (info, index) =>
            info !== null && <TextCardInfo key={index}>{info}</TextCardInfo>
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {Array.isArray(activityViewDetail)
        ? (() => {
            const locationItem = activityViewDetail.find(
              (item) => item.Name === 'Location'
            );
            return locationItem ? processItem(locationItem) : null;
          })()
        : activityViewDetail.Name === 'Location'
        ? processItem(activityViewDetail)
        : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 25,
  },
});

export default MeetingActivityItemInfo;
