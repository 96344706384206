export const projectsId = {
  '2022': 'acef66f1-236e-48cf-90c2-5be4e157968a',
  '2023': '2fe9a31e-aa0c-4a57-98e1-4ff50a784eee',
  '2024': 'dc7f3030-0287-4f8d-b869-e7a9a90ca8b0',
};
export const locationMap = {
  Austin: 'loc01',
  'Lisbon - Epic Sana': 'loc02',
  'Lisbon - Corinthia': 'loc02',
  'Big Island': 'loc03',
  'Riviera Maya': 'loc04',
};
export const imageLocation = {
  locationOneGolden: '../../../assets/loc01_GoldenCircle.png',
  locationOneWinner: '../../../assets/loc01_Winner.png',
};
///
