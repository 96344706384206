import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Image } from 'expo-image';
import TextUser from '../TextComponents/Home/TextUser';
import TextIntro from '../TextComponents/Home/TextIntro';
import UserPoints from '../UserPoints';
import Line from '../Line';
import Weather from '../Weather';
import { useSecureContext } from '../../context';
import {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from 'react-native-reanimated';
// import { imageLocation } from '../../utils/settings';
import { checkGoldenStatus } from '../../utils';
import { LocationWithMaybeWave } from '../../types';
import { shadowConst } from '../../globalStyles';
export interface HomeHeaderProps {
  firstName: string;
  location?: string;
  numberOfPoints?: string;
  locationAndWave?: LocationWithMaybeWave | null;
}

const HomeHeader = ({
  firstName,
  location,
  numberOfPoints,
}: HomeHeaderProps) => {
  const { locationAndWave } = useSecureContext();

  /////////////////////// ANIMATION ///////////////////////
  const opacityImageAnimated = useSharedValue(1);
  const animatedImageStyle = useAnimatedStyle(() => ({
    opacity: opacityImageAnimated.value,
  }));
  ///
  const imagesLocation = {
    loc01GoldenCircle_: require('../../../assets/loc01_GoldenCircle.png'),
    loc01_Winner: require('../../../assets/loc01_Winner.png'),
    loc02_GoldenCircle: require('../../../assets/loc02_GoldenCircle.png'),
    loc02_Winner: require('../../../assets/loc02_Winner.png'),
    loc03_GoldenCircle: require('../../../assets/loc03_GoldenCircle.png'),
    loc03_Winner: require('../../../assets/loc03_GoldenCircle.png'),
    loc04_GoldenCircle: require('../../../assets/loc04_GoldenCircle.png'),
    loc04_Winner: require('../../../assets/loc04_GoldenCircle.png'),
    golden24: require('../../../assets/gwc24/GWC24DigitalgaendaGoldenCircleHeader.jpg'),
    winner24: require('../../../assets/gwc24/GWC 24 Digital Agenda Winners Header.jpg'),
  };

  if (!location) return null;

  let isGolden;
  if (locationAndWave?.wave?.ProjectRoles) {
    isGolden = checkGoldenStatus(locationAndWave?.wave?.ProjectRoles);
  }
  return (
    <View style={style.container}>
      <View style={style.imageContainer}>
        <Image
          source={isGolden ? imagesLocation.golden24 : imagesLocation.winner24}
          contentFit="cover"
          style={[style.image, animatedImageStyle]}
          transition={{
            duration: 300,
            timing: 'linear',
          }}
          onLoadEnd={() => {
            opacityImageAnimated.value = withTiming(1, {
              duration: 300,
              easing: Easing.linear,
            });
          }}
        />
      </View>
      <TextUser>Hi {firstName}</TextUser>
      <TextIntro>{`Welcome to ${location}!`}</TextIntro>
      <TextIntro>Scroll to explore your Winners Circle experience.</TextIntro>
      <UserPoints numberOfPoints={numberOfPoints} />
      <Line />
      <Weather location={locationAndWave?.Name} />
    </View>
  );
};
const style = StyleSheet.create({
  container: {
    flex: 1,
  },
  imageContainer: {
    shadowOffset: {
      width: shadowConst.width,
      height: shadowConst.height,
    },
    shadowOpacity: shadowConst.opacity,
    elevation: shadowConst.elevation,
    shadowRadius: shadowConst.radius,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  image: {
    flex: 1,
    width: '100%',
    aspectRatio: 2.505,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
});

export default HomeHeader;
