import { useState, useEffect } from 'react';
import { useSecureContext } from '../context';
import { getTokens } from '../helpers/get-tokens';

const useGetTokens = () => {
  const { token, setState, initialized, contextTokens } = useSecureContext();
  const [tokens, setTokens] = useState<{
    CmsApiAccessKey: string;
    WeatherApiAccessKey: string;
  } | null>(null);

  useEffect(() => {
    if (token && !contextTokens) {
      getTokens(token).then((tokens) => {
        setTokens(tokens);
        setState((prev) => {
          if (!prev || !initialized) {
            return prev;
          }

          if (!prev.contextTokens) {
            return {
              ...prev,
              contextTokens: tokens,
            };
          }

          return prev;
        });
      });
    }
  }, [token, setTokens, setState, initialized, contextTokens]);
  if (contextTokens) {
    return contextTokens;
  }
  return tokens;
};
export default useGetTokens;
