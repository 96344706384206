import React from 'react';
import {
  Text,
  ImageBackground,
  StyleSheet,
  useWindowDimensions,
  Pressable,
  View,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import placeholderImage from '../../../assets/agenda-background/agenda-placeholder.jpg';
import { colourConst, fontFamilyConst } from '../../globalStyles';
import { sub } from 'date-fns';
interface PhotoCategoryProps {
  name: string;
  backgroundImageId: string | undefined;
  categoryId: string;
  photoLength?: number | string;
  style?: object;
}

const PhotoCategory: React.FC<PhotoCategoryProps> = ({
  name,
  backgroundImageId,
  categoryId,
  photoLength,
  style,
}) => {
  const { width } = useWindowDimensions();
  const circleSize = width * 0.45;
  const navigation = useNavigation();
  const styles = StyleSheet.create({
    container: {
      width: circleSize,
      alignItems: 'center',
      marginBottom: 16,
      marginHorizontal: 8,
      position: 'relative',
      maxWidth: 600,
      justifyContent: 'center',
      margin: 'auto',
      flex: 1,
    },
    circle: {
      width: circleSize,
      height: circleSize,
      borderRadius: circleSize / 2,
      overflow: 'hidden',
      borderWidth: 3,
      borderColor: 'white',
      maxWidth: 300,
      maxHeight: 300,
    },
    circleImage: {
      resizeMode: 'cover',
    },
    name: {
      fontFamily: fontFamilyConst.regular,
      color: colourConst.blue,
      marginTop: 8,
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center',
      height: 40,
    },
    photoLength: {
      fontFamily: fontFamilyConst.bold,
      color: colourConst.white,
      fontSize: 14,
      textAlign: 'center',
      backgroundColor: colourConst.blue,
      borderRadius: 100,
      padding: 0,
      position: 'absolute',
      bottom: 60,
      right: 10,
      width: 35,
      height: 35,
      justifyContent: 'center',
    },
    subContainer: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  const handlePress = () => {
    navigation.navigate('PhotoGallery', { name: name, id: categoryId });
  };
  return (
    <Pressable style={[styles.container, style]} onPress={handlePress}>
      <View style={styles.subContainer}>
        <ImageBackground
          source={{
            uri: backgroundImageId ? backgroundImageId : placeholderImage,
          }}
          style={styles.circle}
          imageStyle={styles.circleImage}
        />
        <Text style={styles.name}>{name}</Text>
        <View style={styles.photoLength}>{photoLength}</View>
      </View>
    </Pressable>
  );
};

export default PhotoCategory;
