import React, { useCallback, useState, useEffect } from 'react';
import {
  FlatList,
  StyleSheet,
  View,
  ImageBackground,
  Image,
} from 'react-native';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import InformationItem from '../components/InformationItem';
import Constants from 'expo-constants';
import Item from '../components/Item';
import Settings from '../components/Settings';
import { colourConst, fontFamilyConst, fontSize } from '../globalStyles';
import { MoreParams } from '../utils/types';
import RainbowLine from '../components/RainbowLine';
import TitleItem from '../components/TextComponents/TitleItem';
import { useSecureContext } from '../context';
import { getInformationPages } from '../helpers/get-information-pages';
import PhotosScreen from '../components/PhotosScreen';
import PhotoGallery from '../components/PhotoGallery';
import LocalExperiences from '../components/LocalExperiences';
import InformationItemPlaceholder from '../components/InformationItemPlaceholder';

const { STRAPI_BASE_URL } = Constants.expoConfig!.extra!;
const Stack = createStackNavigator();

const List = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { initialized, locationAndWave, setState, token } = useSecureContext();
  const [pages, setPages] = useState<any[]>([]);
  const wave = locationAndWave?.wave?.ProjectId;
  const isGolden = locationAndWave?.wave?.ProjectRoles?.find(
    (wave) => wave.Reference === 'GCA'
  );

  const settingsData = {
    id: 1000,
    attributes: {
      Title: 'Settings',
      Image_Url: require('../../assets/moreImg/Settings.png'),
      Information: 'Log out',
    },
  };

  const filterSettingsData = (pages, waveId, isGolden) => {
    return pages.filter((item) => {
      if (
        waveId === '268578f1-f878-41b2-a2f9-f0fd5985db73' ||
        waveId === '4a5d3940-9a69-47cd-8478-2d57ebbed96b'
      ) {
        if (isGolden) {
          return item.id !== 229 && item.id !== 47 && item.id !== 97;
        } else {
          return item.id !== 230 && item.id !== 97 && item.id !== 47;
        }
      } else {
        if (isGolden) {
          return item.id !== 47;
        } else {
          return item.id !== 97;
        }
      }
    });
  };

  const filteredSettingsData = filterSettingsData(pages, wave, isGolden);
  // = pages.filter((item) => {
  //   if (isGolden) {
  //     return item.id !== 47;
  //   }
  //   return item.id !== 97;
  // });
  const pagesWithSettings = [...filteredSettingsData, settingsData];
  useEffect(() => {
    setIsLoading(true); // Start loading
    fetch(
      `${STRAPI_BASE_URL}/api/auth/auth0/callback?access_token=${token}`,
      {}
    )
      .then((response) => {
        if (response.status === 401) {
          throw new Error('Unauthorized access');
        }
        return response.json();
      })
      .then((strapiTokenResponse) => {
        getInformationPages(strapiTokenResponse.jwt, wave).then(({ data }) => {
          setPages(data);
          setIsLoading(false); // Data is ready
        });

        setState((prev) => {
          if (!prev || !strapiTokenResponse || !initialized) {
            return prev;
          }

          if (!prev.strapiTokenResponse) {
            return {
              ...prev,
              strapiTokenResponse: strapiTokenResponse,
            };
          }

          return prev;
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false); // Stop loading on error
      });
  }, [initialized, setState, token, wave]);
  useEffect(() => {
    console.log('isLoading :>> ', isLoading);
  }, [isLoading]);
  const navigation = useNavigation<NavigationProp<MoreParams, 'List'>>();

  const onPress = useCallback(
    (item) => {
      if (item.Title === 'Settings') {
        navigation.navigate('Settings', { item });
      } else if (item.Title === 'Photos') {
        navigation.navigate('PhotosScreen', { item });
      } else if (item.Title === 'Local Experiences') {
        navigation.navigate('LocalExperiences', { item });
      } else {
        navigation.navigate('Item', { item });
      }
    },
    [navigation]
  );
  if (isLoading) {
    return (
      <Image
        style={styles.loader}
        source={require('../../assets/loader.gif')}
      />
    );
  }
  return (
    <View style={styles.pageContainer}>
      <RainbowLine>
        <ImageBackground
          source={require('../../assets/watermark.png')}
          style={styles.background}
        >
          <View style={styles.container}>
            {!isLoading && (
              <FlatList
                style={styles.cardContainer}
                data={pagesWithSettings}
                numColumns={2}
                columnWrapperStyle={styles.columns}
                renderItem={({ item }) => (
                  <InformationItem
                    title={item.attributes.Title}
                    information={item.attributes.Information}
                    imageUrl={item.attributes.Image_Url}
                    onPress={() => onPress(item.attributes)}
                  />
                )}
                keyExtractor={(item) => item.attributes.Title}
              />
            )}
          </View>
        </ImageBackground>
      </RainbowLine>
    </View>
  );
};

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    justifyContent: 'center',

    alignSelf: 'center',
  },
  container: {
    flex: 1,
    marginBottom: 10,
  },
  columns: {
    paddingHorizontal: 10,
    gap: 20,
  },
  cardContainer: {
    marginTop: 5,
  },
  background: {
    flex: 1,
  },
  loader: {
    flex: 1,
    width: 100,
    height: 100,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
});
const MoreNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="More Information"
      screenOptions={{
        cardStyle: {
          backgroundColor: colourConst.background,
          marginBottom: 0,
          flex: 1,
        },
      }}
    >
      <Stack.Screen
        name="More Information"
        component={List}
        options={{
          headerTitle: 'More Information',
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
          headerTitleStyle: {
            fontFamily: fontFamilyConst.bold,
            fontSize: fontSize.large,
          },
          cardStyle: {
            backgroundColor: colourConst.background,
            marginBottom: 0,
            flex: 1,
          },
        }}
      />
      <Stack.Screen
        name="Item"
        component={Item}
        options={{
          headerTitle: () => {
            return <TitleItem />;
          },
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
        }}
      />

      <Stack.Screen
        name="Settings"
        component={Settings}
        options={{
          headerTitle: () => {
            return <TitleItem />;
          },
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
          transitionSpec: {
            open: { animation: 'timing', config: { duration: 1000 } },
            close: { animation: 'timing', config: { duration: 1000 } },
          },
        }}
      />
      <Stack.Screen
        name="LocalExperiences"
        component={LocalExperiences}
        options={{
          headerTitle: () => {
            return <TitleItem />;
          },
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
        }}
      />
      <Stack.Screen
        name="PhotosScreen"
        component={PhotosScreen}
        options={{
          headerTitle: () => {
            return <TitleItem />;
          },
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
        }}
      />
      {/* <Stack.Screen
        name="PhotoGallery"
        component={PhotoGallery}
        options={{
          headerTitle: () => {
            return <TitleItem />;
          },
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
        }}
      /> */}
      <Stack.Screen
        name="PhotoGallery"
        component={PhotoGallery}
        options={({ route }) => ({ title: route.params.name })}
      />
    </Stack.Navigator>
  );
};

export default React.memo(MoreNavigation);
