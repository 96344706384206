import { DigitalAssetUseType, DigitalAssetType } from '../utils/enums';
import Constants from 'expo-constants';

type GetDigitalAssetsUrlProps = {
  digitalAssetType: DigitalAssetType;
  idsGuidsCsv: string;
  digitalAssetUse?: DigitalAssetUseType;
};

const getDigitalAssetsUrl = (parameters: GetDigitalAssetsUrlProps) => {
  const { NEXT_PUBLIC_ASSETS_BASE_URL } = Constants.expoConfig!.extra!;
  const baseUrl = `${NEXT_PUBLIC_ASSETS_BASE_URL}/digital-assets/urls/view/${parameters.digitalAssetType}`;
  const url = new URL(baseUrl);
  const queryParams = new URLSearchParams();
  //optional chaining idsGuidsCsv
  queryParams.append('idsGuidsCsv', parameters.idsGuidsCsv.toString());

  if (parameters.digitalAssetUse !== undefined) {
    queryParams.append(
      'digitalAssetUse',
      parameters.digitalAssetUse.toString()
    );
  }
  url.search = queryParams.toString();
  return url.toString();
};
export default getDigitalAssetsUrl;
