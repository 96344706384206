import React from 'react';
import { View, Image, StyleSheet, ScrollView } from 'react-native';
import TestGallery from '../Gallery';
import { useSecureContext } from '../../context';
import { getMedias } from '../../helpers/photos';
import { useQuery } from 'react-query';
import { useIdsBatchMaker } from '../../hooks/useIdBatchMaker';
import RainbowLine from '../RainbowLine';
interface PhotoGalleryProps {
  route: {
    params: {
      categoryName: string;
      id: string;
    };
  };
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ route }) => {
  const categoryId = route.params?.id;
  const { token, locationAndWave } = useSecureContext();
  const waveId = locationAndWave?.wave?.ProjectId;

  const {
    data: medias,
    isLoading: isLoadingMedias,
    isPreviousData,
  } = useQuery({
    queryKey: ['medias', waveId, categoryId],
    queryFn: async () => getMedias(token, categoryId, waveId),
  });
  const mediaIdBatches = useIdsBatchMaker(medias);
  return (
    <View style={{ flex: 1 }}>
      <RainbowLine>
        {isLoadingMedias &&
        mediaIdBatches &&
        mediaIdBatches?.length === 0 &&
        !isPreviousData ? (
          <Image
            style={styles.loader}
            source={require('../../../assets/loader.gif')}
          />
        ) : (
          <View style={styles.container}>
            <TestGallery medias={medias} mediasBatches={mediaIdBatches} />
          </View>
        )}
      </RainbowLine>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: '#fff' },

  loader: {
    width: 100,
    height: 100,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
});

export default PhotoGallery;
