import React from 'react';
import {
  Image,
  TouchableOpacity,
  Text,
  StyleSheet,
  ImageBackground,
  View,
} from 'react-native';
import { NavigationProp, useFocusEffect } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { colourConst, fontFamilyConst, fontSize } from '../globalStyles';
import { SocialCircleParams } from '../utils/types';
import { useSecureContext } from '../context';
import { useGetPostsWithToken } from '../hooks/useGetPostsWithToken';
import SocialPost from '../components/SocialPost';
import { FlatList } from 'react-native-gesture-handler';
import CreatePost from '../components/CreatePost';
import Comments from '../components/Comments';
import RainbowLine from '../components/RainbowLine';
const Stack = createStackNavigator();

const SocialCircleScreen = () => {
  const { locationAndWave, setState } = useSecureContext();
  const fakeData = [
    {
      id: 928,
      attributes: {
        createdAt: '2025-04-03T09:16:14.092Z',
        text: '',
        updatedAt: '2025-04-03T09:16:14.092Z',
        publishedAt: '2025-04-03T09:16:14.085Z',
        Image_Url: '52cb0a68-3127-4682-afe1-aa9be91f06f6',
        Given_Name: 'Winners Circle',
        Family_Name: 'Team',
        Participant_Id: 'f3380484-b81e-4436-a1a2-85a3106703fc',
        Media_Type: 'image',
        comments: { data: [] },
        likes: { data: [] },
        Text: 'test',
        wave: {
          data: {
            id: 21,
            attributes: {
              Title: 'Gartner Winners Circle 2024 Carlsbad Wave 1',
              Start_Date: '2025-04-04',
              End_Date: '2025-04-07',
              UUID: 'e09a00e8-4050-471b-830e-5dbb0327aa3c',
              Reference: 'Wave 1',
              createdAt: '2023-12-18T13:55:07.577Z',
              updatedAt: '2025-01-13T23:22:49.362Z',
              publishedAt: '2023-12-18T13:55:16.752Z',
            },
          },
        },
      },
    },
    {
      id: 895,
      attributes: {
        createdAt: '2025-01-23T12:27:33.557Z',
        Text: 'Hello, test',
        updatedAt: '2025-01-23T12:27:33.557Z',
        publishedAt: '2025-01-23T12:27:33.546Z',
        Image_Url: 'fcd1a3cb-fe0b-465b-a880-f690e0c55e31',
        Given_Name: 'Winners Circle',
        Family_Name: 'Team',
        Participant_Id: 'f3380484-b81e-4436-a1a2-85a3106703fc',
        Media_Type: 'image',
        comments: {
          data: [],
        },
        likes: {
          data: [],
        },
        wave: {
          data: {
            id: 21,
            attributes: {
              Title: 'Gartner Winners Circle 2024 Carlsbad Wave 1',
              Start_Date: '2025-04-04',
              End_Date: '2025-04-07',
              UUID: 'e09a00e8-4050-471b-830e-5dbb0327aa3c',
              Reference: 'Wave 1',
              createdAt: '2023-12-18T13:55:07.577Z',
              updatedAt: '2025-01-13T23:22:49.362Z',
              publishedAt: '2023-12-18T13:55:16.752Z',
            },
          },
        },
      },
    },
  ];
  const { posts, isLoading, isError, error, refetch } = useGetPostsWithToken();
  useFocusEffect(
    React.useCallback(() => {
      refetch();
    }, [refetch])
  );
  if (isLoading) {
    return (
      <Image
        style={styles.loader}
        source={require('../../assets/loader.gif')}
      />
    );
  }

  if (isError) {
    console.log('Error in SocialCircleScreen: ', error);
    if (
      !!error &&
      typeof error === 'object' &&
      'status' in error &&
      error.status === 401
    ) {
      setState((prev) => ({
        ...prev,
        token: null,
        state: null,
      }));
    }
    return <Text>Something went wrong {error?.message}</Text>;
  }
  return (
    <View style={styles.pageContainer}>
      <RainbowLine>
        <ImageBackground
          source={require('../../assets/watermark.png')}
          style={styles.background}
        >
          <FlatList
            style={styles.postFeed}
            data={posts?.data}
            // data={fakeData}
            contentContainerStyle={styles.container}
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => (
              <SocialPost
                imageUrl={item.attributes.Image_Url}
                mediaType={item.attributes.Media_Type}
                text={item.attributes.Text}
                date={item.attributes.createdAt}
                comments={item.attributes.comments?.data}
                likes={item.attributes.likes?.data}
                id={item.id}
                firstName={item.attributes.Given_Name}
                lastName={item.attributes.Family_Name}
                participantId={item.attributes.Participant_Id}
                refetch={refetch}
              />
            )}
          />
        </ImageBackground>
      </RainbowLine>
    </View>
  );
};

const SocialCircleHeaderRight = () => {
  const { locationAndWave } = useSecureContext();
  const isGuest = locationAndWave?.wave?.ProjectRoles.find(
    (role) => role.Reference === 'Guest'
  );
  const navigation = useNavigation<NavigationProp<SocialCircleParams>>();
  if (!isGuest) {
    return (
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('New Post');
        }}
        style={styles.newContainer} // Add this line
      >
        <Text style={styles.new}>+</Text>
      </TouchableOpacity>
    );
  }
  return null;
};

const NewPostHeaderRight = () => {
  const navigation = useNavigation<NavigationProp<SocialCircleParams>>();

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('Comments', { text: 'FIXME' });
      }}
    >
      <Text>Next</Text>
    </TouchableOpacity>
  );
};

const NewPostMediaHeaderLeft = () => {
  const navigation = useNavigation<NavigationProp<SocialCircleParams>>();
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.popToTop();
      }}
    >
      <Text style={styles.close}>+</Text>
    </TouchableOpacity>
  );
};

const SocialCircleNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="SocialCircle"
      screenOptions={{
        cardStyle: {
          backgroundColor: colourConst.background,
        },
      }}
    >
      <Stack.Screen
        name="Social Circle"
        component={SocialCircleScreen}
        options={{
          headerTitle: 'Social Circle',
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
          headerTitleStyle: {
            fontFamily: fontFamilyConst.bold,
            fontSize: fontSize.large,
          },
          headerRight: SocialCircleHeaderRight,
        }}
      />
      <Stack.Screen
        name="New Post"
        component={CreatePost}
        options={{
          headerTitle: 'New Post',
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
          headerTitleStyle: {
            fontFamily: fontFamilyConst.bold,
            fontSize: fontSize.large,
          },
          // headerRight: NewPostHeaderRight,
          headerLeft: NewPostMediaHeaderLeft,
        }}
      />
      <Stack.Screen
        name="Comments"
        component={Comments}
        options={{
          headerTitle: 'Comments',
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
          headerTitleStyle: {
            fontFamily: fontFamilyConst.bold,
            fontSize: fontSize.large,
          },
          // headerRight: NewPostMediaHeaderRight,
        }}
      />
    </Stack.Navigator>
  );
};
const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  container: {
    flex: 1,
    // flexGrow: 1,
    // alignItems: 'center',
  },
  postFeed: {
    // width: '90%',
    flex: 1,
    // width: 358,
    // alignSelf: 'center',
  },
  loader: {
    flex: 1,
    width: 100,
    height: 100,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  newContainer: {
    height: 20,
    width: 20,
    marginTop: 5,
    marginRight: 10,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: colourConst.blue,
    justifyContent: 'center',
    alignItems: 'center',
  },
  new: {
    color: colourConst.blue,
    fontFamily: fontFamilyConst.regular,
    fontSize: 25,
  },
  close: {
    color: colourConst.font,
    fontFamily: 'Roboto',
    fontSize: 35,
    fontWeight: 100,
    marginLeft: 10,
    transform: [{ rotate: '45deg' }],
  },
  background: {
    flex: 1,
  },
});

export default React.memo(SocialCircleNavigation);
