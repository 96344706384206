import React from 'react';
import { TimeSlotAgendaProps } from '../../utils/types';
import AgendaEvent from '../AgendaEvent';
import WaitListEvent from '../WaitListEvent';
import BookingEvent from '../BookingEvent';
import { useTimeslotHandler } from './utils';
import { TimeslotType } from '../../utils/enums';
import MeetingEvent from '../MeetingEvent';

const TimeSlotAgenda = ({
  timeBegin,
  timeEnd,
  bookingStatus,
  activityName,
  activityType,
  activeTab,
  agendaViewDetail,
  imageUrl,
}: TimeSlotAgendaProps) => {
  const { activityStarts, activityFinishs, eventStatus } = useTimeslotHandler(
    timeBegin,
    timeEnd,
    activityType,
    bookingStatus
  );
  switch (eventStatus) {
    case TimeslotType.TimeslotEvent:
      return (
        <AgendaEvent
          timeBegin={activityStarts}
          timeEnd={activityFinishs}
          activityName={activityName}
          activeTab={activeTab}
          agendaViewDetail={agendaViewDetail}
          imageUrl={imageUrl}
        />
      );

    case TimeslotType.TimeslotBooking:
      return (
        <BookingEvent
          timeBegin={activityStarts}
          timeEnd={activityFinishs}
          activityName={activityName}
          agendaViewDetail={agendaViewDetail}
          activeTab={activeTab}
          imageUrl={imageUrl}
        />
      );

    case TimeslotType.TimeslotWaitlist:
      return (
        <WaitListEvent
          timeBegin={activityStarts}
          timeEnd={activityFinishs}
          activityName={activityName}
          agendaViewDetail={agendaViewDetail}
          imageUrl={imageUrl}
        />
      );

    default:
      return (
        <AgendaEvent
          timeBegin={activityStarts}
          timeEnd={activityFinishs}
          activityName={activityName}
          activeTab={activeTab}
          agendaViewDetail={agendaViewDetail}
          imageUrl={imageUrl}
          activityType={activityType}
        />
      );
  }
};

export default TimeSlotAgenda;
