import { Text, StyleSheet } from 'react-native';
import {
  colourConst,
  fontSize,
  fontFamilyConst,
} from '../../../../globalStyles';
import { TextProps } from '../../../../utils/types';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const TextCardInfo = (props: TextProps) => {
  return (
    <ReactMarkdown
      components={{
        p: ({ children }) => <Text style={styles.text}> {children}</Text>,
        ul: ({ children }) => <Text style={styles.ulText}> {children}</Text>,
      }}
    >
      {props.children}
    </ReactMarkdown>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.regular,
    fontSize: fontSize.medium,
    color: colourConst.font,
    textAlign: 'left',
    lineHeight: 24,
    marginLeft: 21,
    marginRight: 9,
    marginBottom: 10,
    textIndent: -4,
  },
  ulText: {
    fontFamily: fontFamilyConst.regular,
    fontSize: fontSize.medium,
    color: colourConst.font,
    textAlign: 'left',
    lineHeight: 15,
    marginLeft: 21,
    marginRight: 9,
    marginBottom: 10,
    textIndent: -4,
  },
});
export default TextCardInfo;
