import React, { useEffect } from 'react';
import { StyleSheet, ImageBackground, View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import AgendaLoader from '../components/AgendaLoader';
import { getAgenda } from '../helpers/get-agenda';
import HomeHeader from '../components/HomeHeader';
import { colourConst, fontFamilyConst, fontSize } from '../globalStyles';
import RainbowLine from '../components/RainbowLine';
import CollapsilbeCard from '../components/CollapsibleCard';
import { convertDateFormat } from '../helpers/date-time';
import { getPerson } from '../helpers/get-agenda';
import { useQueryWithToken } from '../query';
import { useSecureContext } from '../context';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from 'react-native-reanimated';
import HomeDropdown from '../components/HomeDropdown';
const Stack = createStackNavigator();

const HomeScreen = () => {
  const { data, refetch } = useQueryWithToken('person', getPerson);
  const { locationAndWave } = useSecureContext();
  const currentWave = locationAndWave?.wave?.ProjectId;
  const opacityText = useSharedValue(0);
  const textStyleAnimated = useAnimatedStyle(() => {
    return {
      opacity: opacityText.value,
    };
  });
  useEffect(() => {
    const refetchInterval = setInterval(() => {
      refetch();
    }, 5 * 60 * 1000); // 5 minutes

    return () => {
      clearInterval(refetchInterval);
    };
  }, [refetch]);

  const numberOfPoints = data?.UserProjects?.find(
    (project) => project.ProjectId === currentWave
  )?.ProjectAttributes?.find(
    (attr) => attr.ExternalIdentifier === 'ParticipantDeleKitPoints'
  );
  locationAndWave?.wave?.ProjectAttributes?.find(
    (attr) => attr.ExternalIdentifier === 'ParticipantDeleKitPoints'
  );
  const renderItem = ({ item, index, filteredData }) => {
    const currentDate = convertDateFormat(item.DateTimeBegin);
    const prevDate =
      index > 0
        ? convertDateFormat(filteredData[index - 1].DateTimeBegin)
        : null;
    const isDifferentDay = !prevDate || prevDate.day !== currentDate.day;
    ////
    return (
      <>
        {isDifferentDay && (
          <Animated.Text
            style={[styles.dividerText, textStyleAnimated]}
            onLayout={() => {
              opacityText.value = withTiming(1, {
                duration: 300,
                easing: Easing.linear,
              });
            }}
          >
            {`${currentDate.dayOfWeek}, ${currentDate.month} ${currentDate.day}`}
          </Animated.Text>
        )}
        <CollapsilbeCard
          activityViewDetail={item.AgendaViewDetail}
          activityType={item.ActivityTypeName}
          img={item.ActivityImageUrlMobile}
          activity={item.ActivityName}
          time={`${currentDate.time} - ${
            convertDateFormat(item.DateTimeEnd).time
          }`}
          date={`${currentDate.month} ${currentDate.day}`}
          location={item.LocationInfo}
          key={item.ActivityId}
        />
      </>
    );
  };
  return (
    <View style={styles.pageContainer}>
      <RainbowLine>
        <ImageBackground
          source={require('../../assets/watermark.png')}
          style={styles.background}
        >
          <AgendaLoader
            queryKey="agenda"
            queryFunction={getAgenda}
            renderItem={renderItem}
            shouldFilterByDate={true}
            renderHeader={() => (
              <HomeHeader
                firstName={data?.FirstName ? data?.FirstName : ''}
                location={locationAndWave?.Name}
                numberOfPoints={
                  numberOfPoints?.Attribute.AttributeValues[0]?.Value
                }
                locationAndWave={locationAndWave}
              />
            )}
          />
        </ImageBackground>
      </RainbowLine>
    </View>
  );
};

const HomeNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={{
        cardStyle: {
          backgroundColor: colourConst.background,
          marginBottom: 0,
        },
      }}
    >
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          headerTitle: HomeDropdown,
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
          headerTitleStyle: {
            fontFamily: fontFamilyConst.bold,
            fontSize: fontSize.large,
          },
        }}
      />
    </Stack.Navigator>
  );
};

export default React.memo(HomeNavigation);

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    justifyContent: 'center',
    // alignItems: 'center',
    alignSelf: 'center',
  },
  dividerText: {
    fontSize: 16,
    fontFamily: fontFamilyConst.bold,
    color: colourConst.blue,
    textAlign: 'center',
    paddingVertical: 8,
    backgroundColor: 'rgba(255, 255, 255,.7)',
    marginTop: 5,
    borderBottomWidth: 1,
    borderBottomColor: colourConst.blue,
    borderTopWidth: 1,
    borderTopColor: colourConst.blue,
  },

  background: {
    flex: 1,
  },
});
