import { useCallback, useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { fetchBatchImageUrls } from '../helpers/photos';
import {
  PhotosCategory,
  CustomDigitalAssets,
  DigitalAssetsWihCategoyId,
} from '../types';
import { DigitalAssetUseType } from '../utils/enums';

interface UseInfiniteImagesFetcherProps<T extends boolean = false> {
  token: string | null;
  QueryKey: string;
  UseType: DigitalAssetUseType;
  batchesArray: string[];
  mediaItems?: PhotosCategory[];
  isFetchingCategories?: T;
}

interface UseImagesFetcherReturn<T> {
  photos: T[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => void;
  getMoreImages: () => void;
  isStale: boolean;
  isPreviousData: boolean;
}

export const useInfiniteImagesFetcher = <T extends boolean = false>({
  token,
  QueryKey,
  UseType,
  batchesArray,
  mediaItems,
  isFetchingCategories = false as T,
}: UseInfiniteImagesFetcherProps<T>): UseImagesFetcherReturn<
  T extends true ? DigitalAssetsWihCategoyId : CustomDigitalAssets
> => {
  type PhotoType = T extends true
    ? DigitalAssetsWihCategoyId
    : CustomDigitalAssets;
  const [photos, setPhotos] = useState<PhotoType[]>([]);
  const numberOfPages = batchesArray.length;

  const {
    data: imagesData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    isStale,
    isPreviousData,
  } = useInfiniteQuery({
    queryKey: [{ QueryKey }, batchesArray, numberOfPages],
    queryFn: async ({ pageParam = 0 }) => {
      return fetchBatchImageUrls(
        token,
        UseType,
        batchesArray[pageParam],
        numberOfPages,
        pageParam
      );
    },
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  });

  const getMoreImages = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    if (imagesData?.pages) {
      const allPhotos = imagesData.pages.flatMap((page) => {
        if (!page || !page.assets) {
          return []; // Return empty array if assets is null/undefined
        }
        return page.assets.map((digitalAsset) => {
          // Find the corresponding media item that has the same ID if mediaItems is provided
          if (isFetchingCategories) {
            const mediaItem = mediaItems?.find(
              (media) => media.CategoryImageryId === digitalAsset.Id
            );
            return {
              ...digitalAsset,
              // add category name and id to the photo
              CategoryName: mediaItem?.Name,
              CategoryId: mediaItem?.Id,
              CategoryCount: mediaItem?.Count,
            } as DigitalAssetsWihCategoyId;
          } else {
            const mediaItem = mediaItems?.find(
              (asset) => asset.Id === digitalAsset.Id
            );

            return {
              ...digitalAsset,
              // Use the IsPortrait property from media if available
              isPortrait: mediaItem?.IsPortrait || false,
            } as CustomDigitalAssets;
          }
        });
      });

      setPhotos(allPhotos as PhotoType[]);
    }
  }, [imagesData, mediaItems]);

  return {
    photos,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    getMoreImages,
    isStale,
    isPreviousData,
  };
};
