import { DigitalAssetType } from '../utils/enums';
import { DigitalAssetWithUrlV1 } from '../../src/helpers/digitalAssetsInterfaces';
import getDigitalAssetsUrl from './getDigitalAssetsUrl';

const fetchDigitalAssets = async (
  token: string | null,
  digitalAssetsParams: {
    digitalAssetType: DigitalAssetType;
    idsGuidsCsv: string;
  }
): Promise<DigitalAssetWithUrlV1[]> => {
  const apiUrl = getDigitalAssetsUrl(digitalAssetsParams);

  const response = await fetch(apiUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};
export default fetchDigitalAssets;
