import { ActivityItem } from '../../utils/types';

export const useTourInfo = (
  activityViewDetail: ActivityItem[] | ActivityItem
) => {
  if (!Array.isArray(activityViewDetail)) {
    return {
      description: null,
      itinerary: null,
      additionalInfo: null,
      weather: null,
      location: null,
    };
  }

  const description = activityViewDetail.find(
    (x) => x.Name === 'Description'
  )?.Content;
  const itinerary = activityViewDetail.find(
    (x) => x.Name === 'Itinerary'
  )?.Content;
  const additionalInfo = activityViewDetail.find(
    (x) => x.Name === 'Additional Information'
  )?.Content;
  const weather = activityViewDetail.find(
    (x) => x.Name === 'Detail if this tour could be affected by bad weather?'
  )?.Content;
  const location = activityViewDetail.find(
    (x) => x.Name === 'Location'
  )?.Content;
  return { description, itinerary, additionalInfo, weather, location };
};
