import { useState, useEffect, useMemo } from 'react';
import { PhotosCategory } from '../types';

export const useIdsBatchMaker = (
  medias: PhotosCategory[] | undefined,
  batchLength = 20,
  isCategory = false
) => {
  const [mediaIdBatches, setMediaIdBatches] = useState<string[]>([]);
  useMemo(() => {
    if (medias && Array.isArray(medias) && medias.length > 0) {
      const batches: string[] = [];

      for (let i = 0; i < medias.length; i += batchLength) {
        const chunk = medias.slice(i, i + batchLength);
        const idString = chunk
          .map((media) => (isCategory ? media.CategoryImageryId : media.Id))
          .join(',');
        batches.push(idString);
      }

      setMediaIdBatches(batches);
      // console.log('Media ID batches created:', batches);
    }
  }, [batchLength, isCategory, medias]);
  return mediaIdBatches;
};
