export enum ActivityType {
  TourOrActivity = 'Tour or Activity',
  AgendaItem = 'Agenda Item',
  GroupEvent = 'Group Event',
  EveningActivity = 'Evening Activity',
  Meeting = 'Meeting',
}
export enum BookingStatus {
  Bookable = 'Bookable',
  Waitlist = 'WaitList',
  FullyBooked = 'FullyBooked',
}
export enum BookingType {
  Booking = 'Booking',
  Waitlist = 'Waitlist',
  WaitListAllTimeslots = 'WaitlistAllTimeslots',
  Reserved = 'Reserved',
}
export enum TimeslotType {
  TimeslotEvent = 'TimeslotEvent',
  TimeslotBooking = 'TimeslotBooking',
  TimeslotWaitlist = 'TimeslotWaitlist',
  TimeslotMeeting = 'TimeslotMeeting',
}
export enum DigitalAssetType {
  Social = 1,
  Photos,
  Videos,
  DelegateImages = 5,
  WebGeneral,
  MobileGeneral,
}
export enum GartnerLevels {
  Years = 1,
  Location,
  Wave,
}
export enum ActivityTypeId {
  LocalExperience = 'dc4972b2-7ffe-4845-a157-8516a1adb314',
  ToursAndActities = 'a9b57046-5381-4cdc-bf20-d88386ec0117',
  EveningActivity = '1912c64d-a843-4997-8bb3-bc74dcc4ec08',
  GroupEvent = '2930cfa8-d390-4cb2-a20b-891bf6a18dbd',
  AgendaItem = '8366cd1c-9d07-45ac-b930-3e57f96b00e1',
  Meeting = 'b1b99040-56d5-40ce-bc0c-5e8a2211f074',
  Screen = '8c23ad7a-90d8-4167-a9bf-3aa7dabb94e5',
}
export enum DigitalAssetUseType {
  Orig,
  Web, // more or less 1 k
  Thumb,
  TwoK,
  FourK,
}
export enum MediaStatusType {
  AwaitingApproval,
  Approved,
  TemporarilyApproved,
  Reject,
  AwaitingChange,
  Hidden,
  ApprovedNotForWeb,
  Duplicate,
}
export enum ValueType {
  IntOrLong,
  Decimal,
  Boolean,
  String,
  DateTime,
  Object,
}
