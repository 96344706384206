import Constants from 'expo-constants';
import { PhotosCategory, DigitalAssets } from '../types';
import {
  DigitalAssetType,
  DigitalAssetUseType,
  MediaStatusType,
} from '../utils/enums';

const { NEXT_PUBLIC_ASSETS_BASE_URL } = Constants.expoConfig!.extra!;

export const getPhotoCategories = async (
  token: string | null,
  waveId: string | undefined
) => {
  const photoCategoriesUrl = `${NEXT_PUBLIC_ASSETS_BASE_URL}/media-categories/${DigitalAssetType.Photos}/with-count-v2/${waveId}?mediaStatusTypeIdsCsv=${MediaStatusType.Approved}`;
  const response = await fetch(photoCategoriesUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.ok) {
    return response.json() as Promise<PhotosCategory[]>;
  }
  throw response;
};
//** new functions  **/
export const getCategoriesImagesUrls = async (
  token: string | null,
  UseType: DigitalAssetUseType,
  idsCSV?: string
) => {
  const url = `${NEXT_PUBLIC_ASSETS_BASE_URL}/digital-assets/urls/view/${DigitalAssetType.Photos}/?digitalAssetUse=${UseType}&idsGuidsCsv=${idsCSV}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.ok) {
    return (await response.json()) as DigitalAssets[];
  }
  throw response;
};
export const getMedias = async (
  token: string | null,
  categoryId: string,
  waveId: string | undefined
) => {
  const mediasUrl = `${NEXT_PUBLIC_ASSETS_BASE_URL}/medias/${DigitalAssetType.Photos}/filter?&projectId=${waveId}&categoryId=${categoryId}&mediaStatusTypeIdsCsv=${MediaStatusType.Approved}`;
  const response = await fetch(mediasUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.ok) {
    return response.json() as Promise<PhotosCategory[]>;
  }
  throw response;
};
///

export async function fetchBatchImageUrls(
  token: string | null,
  UseType: DigitalAssetUseType,
  ids: string | undefined,
  numberOfBatches: number,
  page: number
) {
  //get assets
  // if (ids) {
  const assets = await getCategoriesImagesUrls(token, UseType, ids);
  return {
    assets,
    nextPage: page + 1 < numberOfBatches ? page + 1 : null,
  };
  // }
}

export const getPhotoIds = async (
  token: string,
  categoryId: string,
  waveId: string
) => {
  const photosUrl = `${NEXT_PUBLIC_ASSETS_BASE_URL}/medias/${DigitalAssetType.Photos}/filter?&projectId=${waveId}&categoryId=${categoryId}&mediaStatusTypeIdsCsv=${MediaStatusType.Approved}`;
  const response = await fetch(photosUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.ok) {
    return (await response.json()) as PhotosCategory[];
  }
  throw response;
};

export async function getImageUrls(token, array, callback) {
  for (const element of array) {
    const imageData = await fetchImageUrl(token, element.Id);
    const imageUrl = {
      src: imageData,
      width: element.IsPortrait ? 2 : 3,
      height: element.IsPortrait ? 3 : 2,
    };
    callback(imageUrl);
  }
}

export async function fetchImageUrl(token, id) {
  try {
    const response = await fetch(
      `${NEXT_PUBLIC_ASSETS_BASE_URL}/digital-assets/urls/view/${DigitalAssetType.Photos}/${id}?digitalAssetUse=1`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}: ${response.statusText}`);
    }

    const result = await response.json();

    if (!result || !result.Url) {
      throw new Error('Unexpected response format or missing URL');
    }

    return result.Url;
  } catch (error) {
    console.error('Error fetching image URL:', error);
    throw error;
  }
}
