import React, { useEffect, useRef, useMemo } from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  Text,
  Image,
  ActivityIndicator,
} from 'react-native';
import { colourConst, fontFamilyConst } from '../../globalStyles';
import { useQuery } from 'react-query';
import { getPhotoCategories } from '../../helpers/photos';
import { useSecureContext } from '../../context';
import PhotoCategory from '../PhotoCategory';
import { useIdsBatchMaker } from '../../hooks/useIdBatchMaker';
import { useInfiniteImagesFetcher } from '../../hooks/useInfiniteImageFetcher';
import RainbowLine from '../RainbowLine';
import { DigitalAssetUseType } from '../../utils/enums';
const PhotosScreen = () => {
  const { token, locationAndWave } = useSecureContext();
  const waveId = locationAndWave?.wave?.ProjectId;
  const { isLoading, isError, data, error } = useQuery('photoCategories', () =>
    getPhotoCategories(token, waveId)
  );
  const observerRef = useRef(null);
  const categories = useMemo(
    () =>
      data
        ?.filter(
          (category) => category.Count !== 0 && category.IsHidden === false
        )
        .sort((a, b) => a.Order - b.Order),
    [data]
  );
  const categoriesImagesIdsBatches = useIdsBatchMaker(categories, 20, true);
  const { photos, hasNextPage, getMoreImages } = useInfiniteImagesFetcher({
    token,
    QueryKey: 'categoryImages',
    UseType: DigitalAssetUseType.Thumb,
    batchesArray: categoriesImagesIdsBatches,
    mediaItems: categories,
    isFetchingCategories: true,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          getMoreImages();
        }
      },
      { threshold: 0.5 }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => observer.disconnect();
  }, [getMoreImages]);

  if (isLoading) {
    return (
      <Image
        style={styles.loader}
        source={require('../../../assets/loader.gif')}
      />
    );
  }
  if (isError) {
    return <Text>Something went wrong {error?.message}</Text>;
  }
  if (data) {
    const categoriesWithPhotos = data
      ?.filter(
        (category) => category.Count !== 0 && category.IsHidden === false
      )
      .sort((a, b) => a.Order - b.Order);
    if (categoriesWithPhotos.length === 0) {
      return (
        <View style={styles.noPhotosTextContainer}>
          <Text style={styles.noPhotosText}>
            A selection of the photos from your event will be posted here each
            day.
          </Text>
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <RainbowLine>
          <FlatList
            data={photos}
            renderItem={({ item, index }) => (
              <PhotoCategory
                name={item.CategoryName}
                backgroundImageId={photos?.[index]?.Url}
                categoryId={item.CategoryId}
                photoLength={
                  item.CategoryCount > 99 ? '99+' : item.CategoryCount
                }
              />
            )}
            numColumns={2}
            // style={styles.container}
            contentContainerStyle={styles.flatList}
            keyExtractor={(item) => item.Id}
            columnWrapperStyle={styles.row}
            ListFooterComponent={
              hasNextPage ? (
                <ActivityIndicator
                  ref={observerRef}
                  size="large"
                  color={colourConst.blue}
                />
              ) : null
            }
          />
        </RainbowLine>
      </View>
    );
  }
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignSelf: 'center',
    // maxWidth: 600,
  },
  flatList: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 16,
    paddingHorizontal: 4,
    flexGrow: 0,
    // maxWidth: 300,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 0,
    // maxWidth: 600,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    flex: 1,
    width: 100,
    height: 100,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  noPhotosText: {
    fontSize: 16,
    textAlign: 'center',
    fontFamily: fontFamilyConst.regular,
    color: colourConst.font,
  },
  noPhotosTextContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
});

export default PhotosScreen;
