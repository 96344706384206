import { Activities, ActivityQueryParams } from './activityInterfaces';
import { ActivityTypeId } from '../utils/enums';
import Constants from 'expo-constants';

export const fetchExperiences = async ({
  token,
  parentProjectId,
}: ActivityQueryParams): Promise<Activities> => {
  const { NEXT_PUBLIC_BASE_URL } = Constants.expoConfig!.extra!;
  if (!token || !parentProjectId) {
    throw new Error('Missing required parameters');
  }

  const url =
    `${NEXT_PUBLIC_BASE_URL}/activities/activitiesview?` +
    `activityProjectId=${parentProjectId}&` +
    `activityTypeId=${ActivityTypeId.LocalExperience}&` +
    `filterOutActivitiesWithNoTimeslots=false`;
  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    const data: Activities = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch activities:', error);
    throw error;
  }
};
