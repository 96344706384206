import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import ReactMarkdown from 'react-markdown';
import { Image } from 'expo-image';
import { colourConst, fontFamilyConst, fontSize } from '../../globalStyles';
import { DigitalAssetWithUrlV1 } from '../../helpers/digitalAssetsInterfaces';
import { Activity } from '../../helpers/activityInterfaces';

interface ExperienceCardProps {
  imagesData: DigitalAssetWithUrlV1 | undefined;
  activity: Activity;
}

const ExperienceCard = ({ imagesData, activity }: ExperienceCardProps) => {
  return (
    <View style={styles.cardContainer}>
      {imagesData ? (
        <Image
          contentFit="contain"
          style={styles.imgExperience}
          source={imagesData.Url}
        />
      ) : (
        <Image
          style={styles.imgExperience}
          source={require('../../../assets/gwc24/GWC24 DEA Generic branding image.jpg')}
        />
      )}
      <View style={styles.textContainer}>
        <Text style={styles.cardTitle}>{activity.Name}</Text>
        <ReactMarkdown
          components={{
            h1: ({ children }) => (
              <Text style={styles.headers}> {children}</Text>
            ),
            h2: ({ children }) => (
              <Text style={styles.headers}> {children}</Text>
            ),
            h3: ({ children }) => (
              <Text style={styles.headers}> {children}</Text>
            ),
            p: ({ children }) => (
              <Text style={styles.cardText}> {children}</Text>
            ),
            a: ({ children, href }) => (
              <a
                href={href as string}
                target="_blank"
                rel="noopener noreferrer"
                style={styles.links}
              >
                {children}
              </a>
            ),
          }}
        >
          {activity.DescriptionLong}
        </ReactMarkdown>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: 'rgba(3, 92, 167, 0.05)',
    borderRadius: 20,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textContainer: {
    paddingHorizontal: 16,
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.large,
    color: colourConst.blue,
    marginBottom: 15,
  },
  imgExperience: {
    // width: '100%',
    height: 220,
    // aspectRatio: 1.5,
    marginBottom: 15,
    // flexShrink: 0,
  },
  cardText: {
    fontFamily: fontFamilyConst.medium,
    fontSize: fontSize.medium,
    color: colourConst.font,
    textAlign: 'left',
    lineHeight: 22,
    marginBottom: 20,
    whiteSpace: 'pre-line',
  },
  links: {
    color: colourConst.blueLink,
  },
  headers: {
    margin: 0,
    marginBottom: 5,
    padding: 0,
    color: colourConst.font,
    fontFamily: fontFamilyConst.medium,
    fontSize: fontSize.medium,
    textAlign: 'left',
    whiteSpace: 'normal',
    fontWeight: 'bold',
  },
});
export default ExperienceCard;
