import {
  ImageBackground,
  View,
  StyleSheet,
  Pressable,
  useWindowDimensions,
  Text,
} from 'react-native';
import { colourConst, fontFamilyConst, fontSize } from '../globalStyles';
import { useAuthorize } from './api.web.ts';
import TextLogin from '../components/TextComponents/TextLogin';
import TextRequestPassword from '../components/TextComponents/Login/TextRequestPassword';
import useLogin from './useLogin';
import PasswordRequestForm from '../components/PasswordRequestForm';
import React from 'react';

const Login = () => {
  const authorizeGartner = useAuthorize('gartner');
  const authorizeGuest = useAuthorize('guest');
  const { height } = useWindowDimensions();
  const { isRequested, handleIsRequested, setIsRequested } = useLogin();

  return (
    <View style={{ ...styles.pageContainer, height: height }}>
      <ImageBackground
        source={require('../../assets/gwc24/gwc-24-digital-agenda-splash-screen.jpg')}
        style={styles.img}
        resizeMode="cover"
      >
        <View style={styles.textContainer}>
          {isRequested && (
            <Text style={styles.instruction}>ENTER YOUR EMAIL ADDRESS:</Text>
          )}
          {!isRequested && (
            <>
              <Pressable style={styles.button} onPress={authorizeGartner}>
                <TextLogin>GARTNER ASSOCIATES</TextLogin>
              </Pressable>
              <Pressable
                style={[styles.button, styles.buttonGuests]}
                onPress={authorizeGuest}
              >
                <TextLogin>GUESTS</TextLogin>
              </Pressable>
              <Pressable onPress={() => setIsRequested(true)}>
                <TextRequestPassword>
                  request guest password
                </TextRequestPassword>
              </Pressable>
            </>
          )}
          {isRequested && (
            <PasswordRequestForm handleIsRequested={handleIsRequested} />
          )}
        </View>
      </ImageBackground>
    </View>
  );
};

export default Login;

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    maxWidth: 600,
    justifyContent: 'center',
    alignSelf: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  button: {
    alignSelf: 'center',
    justifyContent: 'flex-end',
    paddingVertical: 8,
    borderRadius: 12,
    elevation: 3,
    backgroundColor: colourConst.blue,
    marginBottom: '5%',
    width: '100%',
    paddingHorizontal: 8,
    border: '2px solid #fff',
    textAlign: 'center',
  },
  buttonGuests: {
    backgroundColor: colourConst.guesButtonLogin,
    marginBottom: '3%',
  },
  img: {
    flex: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  textContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    gap: 20,
    width: '70%',
    // bottom: '5%',
  },
  instruction: {
    fontFamily: fontFamilyConst.medium,
    color: colourConst.white,
    textAlign: 'center',
    fontSize: fontSize.smallMedium,
    textShadowColor: '#000000B2',
    textShadowOffset: {
      width: 0,
      height: 4,
    },
    textShadowRadius: 4,
    marginBottom: 20,
  },
});
