import { useState, useMemo, useEffect, useCallback } from 'react';
import { useSecureContext } from '../../context';
import { getPerson } from '../../helpers/get-agenda';
import { useQueryWithToken } from '../../query';
import { LocationWithWaves } from '../../types';
import { projectsId } from '../../utils/settings';
import { GartnerLevels } from '../../utils/enums';
export const useHomeDropdown = () => {
  const [open, setOpen] = useState(false);
  const { initialized, locationAndWave, setState } = useSecureContext();
  const { data } = useQueryWithToken('person', getPerson);

  const userData = useMemo(() => {
    if (!data) {
      return null;
    }

    const { ParticipantId, FirstName, LastName } = data;

    return { ParticipantId, FirstName, LastName };
  }, [data]);

  const projects2024 = useMemo(() => {
    if (!data) {
      return null;
    }
    return data.UserProjects.filter(
      (project) => project.RootId === projectsId[2024]
    );
  }, [data]);

  const locationsAndWaves = useMemo(() => {
    if (!projects2024) {
      return null;
    }

    const locations = projects2024.filter(
      (project) => project.Level === GartnerLevels.Location
    );

    return locations
      .map(
        (location): LocationWithWaves => ({
          ...location,
          waves: projects2024.filter(
            (wave) => wave.ParentId === location.ProjectId
          ),
        })
      )
      .sort((a, b) => a.Reference.localeCompare(b.Reference));
  }, [projects2024]);

  const currentLocationWaves = useMemo(() => {
    if (!projects2024 || !locationAndWave) {
      return null;
    }

    return projects2024.filter(
      (wave) => wave.ParentId === locationAndWave.ProjectId
    );
  }, [projects2024, locationAndWave]);

  useEffect(() => {
    if (!userData) {
      return;
    }

    setState((prev) => {
      if (!prev || !userData || !initialized) {
        return prev;
      }

      if (
        prev.locationAndWave &&
        prev.locationAndWave.RootId !== projectsId[2024]
      ) {
        return {
          ...prev,
          locationAndWave: null,
          userData,
        };
      }

      return {
        ...prev,
        userData,
      };
    });
  }, [userData, setState, initialized]);

  useEffect(() => {
    const firstLocation = locationsAndWaves?.[0];

    setState((prev) => {
      if (!prev || !firstLocation || !initialized) {
        return prev;
      }

      if (!prev.locationAndWave) {
        return {
          ...prev,
          locationAndWave: {
            ...firstLocation,
            wave:
              [...firstLocation.waves]?.sort((a, b) =>
                a.Reference.localeCompare(b.Reference)
              )?.[0] ?? null,
          },
        };
      }

      return prev;
    });
  }, [locationsAndWaves, locationAndWave, setState, initialized]);

  useEffect(() => {
    const onClickAnywhere = () => {
      setOpen(false);
    };

    if (open) {
      window.addEventListener('click', onClickAnywhere);
    }

    return () => {
      window.removeEventListener('click', onClickAnywhere);
    };
  }, [open]);

  const onPress = useCallback(() => {
    setOpen((state) => !state);
  }, []);

  return {
    open,
    setOpen,
    userData,
    locationsAndWaves,
    locationAndWave,
    currentLocationWaves,
    onPress,
    setState,
  };
};
